import 'bootstrap';
// import { Alert, Button, Carousel, Collapse, Dropdown, Modal, Offcanvas, Popover, ScrollSpy, Tab, Toast, Tooltip } from 'bootstrap';
import '../scss/index.scss';
import './fslightbox';
import Swiper, { Navigation, Thumbs } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';

import setShareLinks from './socialShare';
import './plantilla';

window.onload = function () {
  setShareLinks(); // FUNCIÓN QUE BUSCA UNAS CLASES EN EL HTML Y GENERA UNOS LINKS PARA COMPARTIR EN RRSS
};

const swiperGaleria1 = new Swiper('.mySwiperGaleria1', {
  loop: true,
  spaceBetween: 0,
  slidesPerView: 4,
  breakpoints: {
    // when window width is >= 320px
    320: {
      slidesPerView: 2,
      spaceBetween: 0,
    },
    // when window width is >= 480px
    480: {
      slidesPerView: 2,
      spaceBetween: 0,
    },
    // when window width is >= 640px
    640: {
      slidesPerView: 3,
      spaceBetween: 0,
    },
    999: {
      slidesPerView: 4,
      spaceBetween: 0,
    },
    1200: {
      slidesPerView: 4,
      spaceBetween: 0,
    },
  },
  freeMode: true,
  watchSlidesProgress: true,
});
const swiperIMGGrande1 = new Swiper('.mySwiperIMGgrande1', {
  // modules: [Navigation, Thumbs],
  loop: true,
  spaceBetween: 10,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  thumbs: {
    swiper: swiperGaleria1,
  },
});
const swiperGaleria2 = new Swiper('.mySwiperGaleria2', {
  loop: true,
  spaceBetween: 0,
  slidesPerView: 4,
  breakpoints: {
    // when window width is >= 320px
    320: {
      slidesPerView: 2,
      spaceBetween: 0,
    },
    // when window width is >= 480px
    480: {
      slidesPerView: 2,
      spaceBetween: 0,
    },
    // when window width is >= 640px
    640: {
      slidesPerView: 3,
      spaceBetween: 0,
    },
    999: {
      slidesPerView: 4,
      spaceBetween: 0,
    },
    1200: {
      slidesPerView: 4,
      spaceBetween: 0,
    },
  },
  freeMode: true,
  watchSlidesProgress: true,
});
const swiperIMGGrande2 = new Swiper('.mySwiperIMGgrande2', {
  // modules: [Navigation, Thumbs],
  loop: true,
  spaceBetween: 10,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  thumbs: {
    swiper: swiperGaleria2,
  },
});
const swiperGaleria3 = new Swiper('.mySwiperGaleria3', {
  loop: true,
  spaceBetween: 0,
  slidesPerView: 4,
  breakpoints: {
    // when window width is >= 320px
    320: {
      slidesPerView: 2,
      spaceBetween: 0,
    },
    // when window width is >= 480px
    480: {
      slidesPerView: 2,
      spaceBetween: 0,
    },
    // when window width is >= 640px
    640: {
      slidesPerView: 3,
      spaceBetween: 0,
    },
    999: {
      slidesPerView: 4,
      spaceBetween: 0,
    },
    1200: {
      slidesPerView: 4,
      spaceBetween: 0,
    },
  },
  freeMode: true,
  watchSlidesProgress: true,
});
const swiperIMGGrande3 = new Swiper('.mySwiperIMGgrande3', {
  // modules: [Navigation, Thumbs],
  loop: true,
  spaceBetween: 10,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  thumbs: {
    swiper: swiperGaleria3,
  },
});
const swiperGaleria4 = new Swiper('.mySwiperGaleria4', {
  loop: true,
  spaceBetween: 0,
  slidesPerView: 4,
  breakpoints: {
    // when window width is >= 320px
    320: {
      slidesPerView: 2,
      spaceBetween: 0,
    },
    // when window width is >= 480px
    480: {
      slidesPerView: 2,
      spaceBetween: 0,
    },
    // when window width is >= 640px
    640: {
      slidesPerView: 3,
      spaceBetween: 0,
    },
    999: {
      slidesPerView: 4,
      spaceBetween: 0,
    },
    1200: {
      slidesPerView: 4,
      spaceBetween: 0,
    },
  },
  freeMode: true,
  watchSlidesProgress: true,
});
const swiperIMGGrande4 = new Swiper('.mySwiperIMGgrande4', {
  // modules: [Navigation, Thumbs],
  loop: true,
  spaceBetween: 10,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  thumbs: {
    swiper: swiperGaleria4,
  },
});
const swiperGaleria5 = new Swiper('.mySwiperGaleria5', {
  loop: true,
  spaceBetween: 0,
  slidesPerView: 4,
  breakpoints: {
    // when window width is >= 320px
    320: {
      slidesPerView: 2,
      spaceBetween: 0,
    },
    // when window width is >= 480px
    480: {
      slidesPerView: 2,
      spaceBetween: 0,
    },
    // when window width is >= 640px
    640: {
      slidesPerView: 3,
      spaceBetween: 0,
    },
    999: {
      slidesPerView: 4,
      spaceBetween: 0,
    },
    1200: {
      slidesPerView: 4,
      spaceBetween: 0,
    },
  },
  freeMode: true,
  watchSlidesProgress: true,
});
const swiperIMGGrande5 = new Swiper('.mySwiperIMGgrande5', {
  // modules: [Navigation, Thumbs],
  loop: true,
  spaceBetween: 10,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  thumbs: {
    swiper: swiperGaleria5,
  },
});


